import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

export default ({ data }) => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Practice Areas | RWK Attorneys" defer={false} />

		<BackgroundImage fluid={[
			data.cover.childImageSharp.fluid,
			`linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0))`
		].reverse()}>
			<div className="container text-center text-md-left d-flex justify-content-start align-items-center" style={{ minHeight: "40vh" }}>
				<div className="row">
					<div className="col">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb pb-0">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Practice Areas</li>
							</ol>
						</nav>
						<h1>Trusted <span className="text-secondary">legal</span><br /> Representation</h1>
						{/* <p className="lead">With over 75 years of combined legal experience serving residents across the State of Alabama, particularly in Jefferson, St. Clair, Blount and Shelby counties.</p> */}
						<p className="lead">Serving residents of Alabama<br /> with over 75 years of<br /> combined legal experience</p>
						<p><Link className="btn btn-primary" to="/practice-areas/#pa">Learn More</Link></p>
					</div>
				</div>
			</div>
		</BackgroundImage>

		<section id="affiliations" className="p-4 jumbotron d-flex align-items-center">
			<div className="container">
				<div className="row text-center justify-content-center">
					<div className="col-6 col-md-3">
						<Img className="img-fluid" fluid={data.UJSA.childImageSharp.fluid} alt="UJSA logo" style={{ maxHeight: '100px', maxWidth: '100px' }} />
					</div>
					<div className="col-6 col-md-3">
						<Img className="img-fluid" fluid={data.AVRatedGood.childImageSharp.fluid} alt="AV Rated Good logo" style={{ maxHeight: '100px', maxWidth: '100px' }} />
					</div>
				</div>
			</div>
		</section>


		<div className="container" id="pa">

			{/* <p className="lead">You've made the decision to hire an attorney. The next step is to pick a firm that can handle your specific legal needs. Choosing the right lawyer can make the difference between a pleasant experience and a frustrating experience. We work together to achieve the best possible results for you.</p> */}

			<div className="row equal">
				<div className="col-12 col-md-6 text-center p-5">
					<Img className="img-fluid" fluid={data.bankruptcy.childImageSharp.fluid} alt="man in blue and brown plaid dress shirt touching his hair"></Img>
					<h4 className="my-2">Bankruptcy Law</h4>
					<p>Some quick example text to build on and make up the bulk of the content.</p>
					<Link to="/practice-areas/bankruptcy" className="btn btn-outline-primary stretched-link">more..</Link>
				</div>
				<div className="col-12 col-md-6 text-center p-5" >
					<Img className="featurette-image img-fluid mx-auto" fluid={data.familyLaw.childImageSharp.fluid} alt="man standing beside his wife teaching their child how to ride a bike" />
					<h4 className="my-2">Family Law</h4>
					<p>Some quick example text to build on and make up the bulk of the content.</p>
					<Link to="/practice-areas/family-law" className="btn btn-outline-primary stretched-link">more..</Link>
				</div>
				<div className="col-12 col-md-6 text-center p-5" >
					<Img className="img-fluid" fluid={data.criminal.childImageSharp.fluid} alt="two hands in hand cuffs" />
					<h4 className="my-2">Criminal Defense</h4>
					<p>Some quick example text to build on and make up the bulk of the content.</p>
					<Link to="/practice-areas/criminal-defense" className="btn btn-outline-primary stretched-link">more..</Link>
				</div>
				<div className="col-12 col-md-6 text-center p-5" >
					<Img className="img-fluid" fluid={data.personalInjury.childImageSharp.fluid} alt="automobile accident with broken front windshield" />
					<h4 className="my-2">Personal Injury</h4>
					<p>ACCIDENTS AND INJURIES</p>
					<Link to="/practice-areas/personal-injury" className="btn btn-outline-primary stretched-link">more..</Link>
				</div>
			</div>


		</div>



	</Layout>
)
export const query = graphql`
  query {
	familyLaw: file(relativePath: { eq: "family-law.jpg" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	criminal: file(relativePath: { eq: "criminal-defense.jpg" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	bankruptcy: file(relativePath: { eq: "bankruptcy.org.jpg" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	personalInjury: file(relativePath: { eq: "accident-law.jpg" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	AVRatedGood: file(relativePath: { eq: "AV-Rated-good.jpg" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	UJSA: file(relativePath: { eq: "UJSA.svg.png" }) {
	  childImageSharp {
		fluid {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
	cover: file(relativePath: { eq: "bham_cityscape.jpg"}) {
		childImageSharp {
		# Specify the image processing specifications right in the query.
		fluid(quality: 100) {
		  ...GatsbyImageSharpFluid_withWebp
		}
	  }
	}
  }
`